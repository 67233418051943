#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout {
    min-width: 1000px;
}

.site-layout .site-layout-background {
    background: #EFF2F5;
}

.site-description-item-profile-p-label {
    display: inline-block;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.site-description-item-profile-wrapper {
    /* margin-bottom: 7px; */
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
}

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    background: #fff;
}